<template>
	<section>
		<CCard>
			<CCardBody>
				<div class="result-detail">
					<div class="result-detail__heading mb-3"
						><div class="d-flex flex-wrap">
							<h5>{{ $t("RiskDetail.RiskResult") }}</h5>
						</div></div
					>

					<div class="result-detail__container">
						<div class="info mb-4">
							<div class="info__group">
								<label class="info__group__label">{{ $t("RiskDetail.CustomerName") }}: </label>
								<div class="info__group__value">{{ get(dataRisk, "customer.fullName") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("RiskDetail.PhoneNumber") }}: </label>
								<div class="info__group__value">{{ get(dataRisk, "customer.phone") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("RiskDetail.BirthYear") }}: </label>
								<div class="info__group__value">{{ get(dataRisk, "customer.birthYear") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("RiskDetail.EvaluationDate") }}: </label>
								<div class="info__group__value">{{ get(dataRisk, "date") }}</div>
							</div>
						</div>

						<!-- Appointment code -->
						<div v-for="(result, index) in riskResults" :key="index" class="form-group">
							<label class="form-group__label">{{ result.question }}</label>
							<div class="form-group__value">{{ getUserAnswer(result.userAnswer) }}</div>
							<a class="form-group__link" @click="viewOptions">{{
								$t("RiskDetail.ViewOptions")
							}}</a>
							<div class="form-group__options d-none">
								<p v-for="(answer, index) in result.answers" :key="index">{{ answer }}</p>
							</div>
						</div>
					</div>

					<div class="result-detail__action text-center">
						<CButton v-bind="{ variant: 'outline' }" color="danger" @click="goToCustomerDetail">{{
							$t("Button.Close")
						}}</CButton>
					</div>
				</div>
			</CCardBody>
		</CCard>
	</section>
</template>

<script>
import { GET_RISK_DETAIL } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("customers")
import { get, isArray } from "lodash-es"

export default {
	name: "CustomerResultDetail",

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			dataRisk: {
				customer: {},
				data: {},
				date: null,
				result: null,
			},
		}
	},

	computed: {
		...mapState(["recentRisk"]),

		riskResults: function () {
			return Object.values(this.dataRisk.data)
		},
	},

	watch: {
		recentRisk: function (val) {
			if (val) this.dataRisk = { ...val }
		},
	},

	created() {
		this.GET_RISK_DETAIL(this.id)
	},

	methods: {
		get,

		...mapActions({ GET_RISK_DETAIL }),

		goToCustomerDetail() {
			this.$router.go(-1)
		},

		viewOptions($event) {
			const target = $event.target
			target.classList.toggle("d-none")
			target.nextElementSibling.classList.toggle("d-block")
		},

		getUserAnswer(answer) {
			if (isArray(answer)) {
				return answer.reduce((total, value) => {
					return total + ", " + value
				})
			}

			return answer
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.result-detail {
	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__container {
		display: grid;
		width: 100%;
		margin-bottom: 15px;
		background-color: $color-white;
		border-radius: 12px;
	}
}

.info {
	max-width: 400px;
	padding: 1rem;
	background: $color-city-light;
	border-radius: 15px;

	&__group {
		display: flex;
		flex-wrap: wrap;
		gap: 4%;

		&__label {
			flex: 0 0 43%;
			max-width: 43%;

			@media (min-width: $xxs) {
				flex: 0 0 33%;
				max-width: 33%;
			}
		}
		&__value {
			flex: 0 0 43%;
			max-width: 43%;

			@media (min-width: $xxs) {
				flex: 0 0 63%;
				max-width: 63%;
			}
		}
	}
}

.form-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;

	@media (max-width: $xxs) {
		grid-template-columns: 1fr;
	}

	&__label {
		color: $color-black-pearl;
		font-weight: 500;
	}

	&__link {
		color: $base-color;
		text-decoration: underline;
		&:hover {
			color: $base-color !important;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	&__options {
		p {
			margin-bottom: 5px;
		}
	}

	&__link,
	&__options {
		@media (max-width: $xxs) {
			display: none;
		}
	}
}
</style>
